import { Card, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from '../../FurtherInformationForm.module.scss';
import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';

import { ReactNode, useEffect, useState } from 'react';
import CustomQuestionCardAttendeeAnswers from '../CustomQuestionCardAttendeeAnswers/CustomQuestionCardAttendeeAnswers';
import CustomQuestionCardBookerAnswers from '../CustomQuestionCardBookerAnswers/CustomQuestionCardBookerAnswers';
import classNames from 'classnames';

interface CustomQuestionCardProps {
  customQuestion: CustomQuestionItem;
  handleUpdateAttendeeAnswer: (
    questionId: string,
    attendeeId: string,
    value: string | null,
  ) => void;
  handleUpdateBookerAnswer: (questionId: string, value: string | null) => void;
  supplierName: string;
  precheckout?: boolean;
  error: ReactNode;
}

const CustomQuestionCard: React.FC<CustomQuestionCardProps> = ({
  customQuestion,
  handleUpdateAttendeeAnswer,
  handleUpdateBookerAnswer,
  supplierName,
  precheckout,
  error,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const { question } = customQuestion;

  // stores a key/value pair of question/attendee id's and a boolean flag to determine whether or not to show the input for YES_NO_INPUT questions
  const [showInput, setShowInput] = useState<Record<string, boolean>>({});

  // automatically add a key/value pair to showInput for each pre-populated attendee answer
  useEffect(() => {
    if (
      question.type === CustomQuestionTypeEnum.YES_NO_INPUT &&
      customQuestion.attendeeAnswers !== null
    ) {
      const newShowInput = { ...showInput };

      customQuestion.attendeeAnswers.forEach((attendeeAnswer) => {
        if (attendeeAnswer.answer && attendeeAnswer.answer !== 'NO') {
          newShowInput[attendeeAnswer.attendeeId] = true;
        }
      });

      setShowInput(newShowInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customQuestion.attendeeAnswers, question?.type]);

  return (
    <Card
      className={classNames(classes.card, { [classes.preCheckoutCard]: precheckout })}
      padding={isMobile ? 24 : 40}
      py={question.type === CustomQuestionTypeEnum.CHECKBOX ? 0 : 24}
    >
      {question.type !== CustomQuestionTypeEnum.CHECKBOX && (
        <Text mb={theme.spacing.sm} fw={700}>
          {question.title.replace(/\[Provider name\]/g, supplierName)}
        </Text>
      )}

      {/* render attendee answers */}
      <CustomQuestionCardAttendeeAnswers
        customQuestion={customQuestion}
        handleUpdateAttendeeAnswer={handleUpdateAttendeeAnswer}
        showInput={showInput}
        setShowInput={setShowInput}
      />
      {/* render booker answers */}
      {customQuestion.attendeeAnswers === null && (
        <CustomQuestionCardBookerAnswers
          customQuestion={customQuestion}
          handleUpdateBookerAnswer={handleUpdateBookerAnswer}
          showInput={showInput}
          setShowInput={setShowInput}
        />
      )}

      <Text c={theme.colors.red[8]} size="sm" mt="xs">
        {error}
      </Text>
    </Card>
  );
};

export default CustomQuestionCard;
